<template>
  <div>
    <WsUpdate
      :modelName="modelName"
      :id="_id"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      titleKey="name"
      :emitInput="true"
      v-model="updateData"
    >
    </WsUpdate>
  </div>
</template>

<script>
import penguin from "@/models/penguin";
export default {
  metaInfo() {
    return {
      title: `編輯${penguin.label}`,
    };
  },
  data: () => ({
    updateData: null,
    modelName: penguin.modelName,
    label: penguin.label,
    fields: penguin.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["ice", "friends"],
            },
          ],
        },
      ],
    },
  }),
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>